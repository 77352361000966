@import "../../../styles/variables.scss";

footer {
  margin-top: 5rem;
  position: relative;
  bottom: 0;

  .line {
    width: 100%;
    height: 1px;
    background-color: $yellow;
  }

  .footerContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.5rem 0;

    .socialLinks {
      display: flex;
      gap: 1rem;

      a img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

@media (max-width: 500px) {
  footer {
    margin-top: 2rem;
  }

  footer span {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 1rem 0 1rem;
  }
}
